var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"elevation-11"},[_c('v-card-title',{staticClass:"accent"},[_c('h3',[_vm._v("Receipts")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticClass:"pt-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{attrs:{"label":"All Customer","dense":""},on:{"click":function($event){return _vm.clearData()}},model:{value:(_vm.allCustomers),callback:function ($$v) {_vm.allCustomers=$$v},expression:"allCustomers"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"prepend-inner-icon":"mdi-account","items":_vm.customers,"item-text":"clastname","item-value":"custledgkey","label":"Customer","persistent-hint":"","return-object":"","single-line":"","dense":"","outlined":"","disabled":_vm.allCustomers},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","readonly":"","outlined":"","dense":""},model:{value:(_vm.formatStartDate),callback:function ($$v) {_vm.formatStartDate=$$v},expression:"formatStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"min":this.calcMinDate(),"max":this.calcMaxDate()},on:{"input":function($event){_vm.startDateMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","readonly":"","outlined":"","dense":""},model:{value:(_vm.formatEndDate),callback:function ($$v) {_vm.formatEndDate=$$v},expression:"formatEndDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"min":this.calcMinDate(),"max":this.calcMaxDate()},on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"btn-large btn-block primary white--text","disabled":!_vm.valid},on:{"click":_vm.getReceipts}},[_vm._v("Get Receipts")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-5",attrs:{"elevation-11":""}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card-text',[_c('v-card',[_c('v-card-title',[_vm._v("TOTAL RECEIPTS")]),_c('v-data-table',{staticClass:"elevation-1 mb-5",attrs:{"headers":_vm.totalheaders,"items":_vm.totals,"hide-default-footer":""}})],1),(this.allCustomers)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"search":_vm.search,"headers":_vm.adminHeaders,"items":_vm.receipts,"item-key":"invkey","loading-text":"Retrieving data... please wait"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.downloadReceipt(item)}}},[_vm._v("mdi-download")])]}}],null,true)}):_vm._e(),(!this.allCustomers)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"search":_vm.search,"headers":_vm.clientHeaders,"items":_vm.receipts,"item-key":"invkey","loading-text":"Retrieving Receipts... please wait"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.downloadReceipt(item)}}},[_vm._v("mdi-download")])]}}],null,true)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }